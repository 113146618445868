import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { EasingLogic, PageScrollService } from 'ngx-page-scroll-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  contactForm: FormGroup;
  year = new Date().getFullYear();
  isTop: boolean = false;

  constructor(private formBuilder: FormBuilder) {  this.contactForm = this.formBuilder.group({}); }

  get ctrl() { return this.contactForm.controls; }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      firstName:  new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
    });
  }

  getErrorMessage(name:string) {
    
    if(this.contactForm.controls[name].errors.required)
      return "Campo obrigatorio";
    else if(this.contactForm.controls[name].errors.email)
      return "E-mail invalido";

      return "";
  }

  finish($event){
    this.isTop = false; 
  }
}
